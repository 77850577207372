/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components';

type Level = {
  onClick?: any;
  level: any;
  onMouseDown?: any;
};

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  label {
    margin-top: 10px;
    input {
      margin-top: 10px;
    }
  }
  margin-bottom: 10px;
`;

export const StyledLevel = styled.button<Level>`
  width: 100%;
  font-size: 16px;
  background-color: white;
  border-radius: 7px;
  border: 2px solid #3b6978;
  box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.25);
  padding: 10px 20px;
  margin: ${(props) => (props.id === 'custom' ? '10px 0 20px' : '10px 0')};
  cursor: pointer;
  border-width: ${(props) => (props.id === props.level ? '4px' : '2px')};
  label {
    input[type='range'] {
      -webkit-appearance: none;
      width: 100%;
      height: 10px;
      background-color: #84a9ac;
      border: 1px solid #7ca3a7;
      border-radius: 10px;
      margin: auto;
      margin-top: 10px;
      transition: all 0.3s ease;
    }
    input[type='range']::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 20px;
      height: 20px;
      background-color: #3b6978;
      border-radius: 30px;
      transition: all 0.5s ease;
    }
  }
`;
