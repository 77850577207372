import styled from 'styled-components';

const StyledGameOptions = styled.div`
  display: flex;
  width: 350px;
  justify-content: space-around;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none;
  margin: 1% 0;
  @media (max-width: 800px) {
    margin: 20px 0;
  }
`;

export default StyledGameOptions;
