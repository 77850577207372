import styled from 'styled-components';

type Panel = {
  panel: string;
};

const StyledPanel = styled.div<Panel>`
  flex-direction: column;
  width: 300px;
  padding: 10px 20px;
  border-radius: 5px;
  background: #e4e4e4d4;
  border: 1px solid #dee0e0;
  z-index: 999;
  color: #222831;
  /* height: 560px; */
  h2 {
    color: #393e46;
  }
  @media (max-width: 800px) {
    background: #e4e4e4f2;
    top: 120px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    box-sizing: border-box;
  }
  top: 20vh;
  position: absolute;
  align-self: ${(props) =>
    props.panel === 'settings' ? 'flex-end' : 'flex-start'};
`;

export default StyledPanel;
