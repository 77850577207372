import { useState, useEffect, useCallback } from 'react';
import { fillGrid } from '../gameHelpers';
import { GameType, Cell } from './useGrid';

export enum GameStatusType {
  NOT_STARTED = 'NOT_STARTED',
  PLAYING = 'PLAYING',
  WON = 'WON',
  LOST = 'LOST',
}

const useGameStatus = (
  game: GameType,
  grid: Cell[][],
): [(x: number, y: number) => void, () => void, GameStatusType] => {
  const [gameStatus, setGameStatus] = useState<GameStatusType>(
    GameStatusType.NOT_STARTED,
  );

  const startGame = useCallback(
    (x, y) => {
      setGameStatus(GameStatusType.PLAYING);
      fillGrid(game.height, game.width, game.outbreaks, grid, x, y);
    },
    [game.outbreaks, game.height, game.width, grid],
  );

  useEffect(() => {
    const cleared = grid.reduce((n: number, row: Cell[]) => {
      row.forEach((cell: Cell) => {
        if (cell.bug === true && cell.cleared === true) {
          setGameStatus(GameStatusType.LOST);
          return;
        }
        if (cell.cleared === true) {
          n += 1;
        }
      });
      return n;
    }, 0);

    const nonMineTiles = game.width * game.height - game.outbreaks;
    if (cleared === nonMineTiles) {
      setGameStatus(GameStatusType.WON);
    }
  }, [grid, game.width, game.height, game.outbreaks]);

  const resetGame = () => {
    setGameStatus(GameStatusType.NOT_STARTED);
  };

  return [startGame, resetGame, gameStatus];
};

export default useGameStatus;
