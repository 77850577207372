export interface Level {
  width: number;
  height: number;
  outbreaks: number;
}

export interface Levels {
  easy: Level;
  normal: Level;
  hard: Level;
}

export const levels: Levels = {
  easy: {
    width: 9,
    height: 9,
    outbreaks: 10,
  },
  normal: {
    width: 16,
    height: 16,
    outbreaks: 40,
  },
  hard: {
    width: 30,
    height: 16,
    outbreaks: 99,
  },
};
