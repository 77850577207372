interface GridObject {
  bug: boolean;
  flagged: boolean;
  adjacent: number;
  unsure: boolean;
  cleared: boolean;
}

export const checkValid = (
  y: number,
  x: number,
  grid: GridObject[][],
): boolean => {
  if (grid[y] && grid[y][x] && grid[y][x].bug === false) {
    return true;
  }
  return false;
};

export const fillGrid = (
  height: number,
  width: number,
  outbreaks: number,
  grid: GridObject[][],
  xCell: number,
  yCell: number,
): GridObject[][] => {
  const newGrid = [...grid];
  let outbreaksPlaced = 0;
  while (outbreaksPlaced < outbreaks) {
    const x = Math.floor(Math.random() * width);
    const y = Math.floor(Math.random() * height);

    if (grid[y][x].bug === false && y !== yCell && x !== xCell) {
      outbreaksPlaced += 1;
      newGrid[y][x] = { ...grid[y][x], bug: true };

      const directions = [
        [y - 1, x - 1],
        [y - 1, x],
        [y - 1, x + 1],
        [y, x - 1],
        [y, x + 1],
        [y + 1, x - 1],
        [y + 1, x],
        [y + 1, x + 1],
      ];

      for (let i = 0; i < directions.length; i += 1) {
        const yAxis = directions[i][0];
        const xAxis = directions[i][1];

        if (checkValid(yAxis, xAxis, grid)) {
          newGrid[yAxis][xAxis] = {
            ...grid[yAxis][xAxis],
            adjacent: grid[yAxis][xAxis].adjacent + 1,
          };
        }
      }
    }
  }
  return newGrid;
};

export const createGrid = (height: number, width: number): GridObject[][] => {
  const grid = Array.from(Array(height), () =>
    new Array(width).fill({
      bug: false,
      adjacent: 0,
      flagged: false,
      cleared: false,
      unsure: false,
    }),
  );
  return grid;
};

export const addFlag = (
  xAxis: number,
  yAxis: number,
  newGrid: GridObject[][],
): GridObject[][] => {
  const updatedGrid = [...newGrid];

  if (updatedGrid[yAxis][xAxis].flagged === false) {
    updatedGrid[yAxis][xAxis] = { ...updatedGrid[yAxis][xAxis], flagged: true };
  } else {
    updatedGrid[yAxis][xAxis] = {
      ...updatedGrid[yAxis][xAxis],
      flagged: false,
    };
  }
  return updatedGrid;
};

export const pad = (num: number, size: number): string => {
  let s = `${num}`;
  while (s.length < size) s = `0${s}`;
  return s;
};
