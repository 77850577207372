import { useState, useEffect } from 'react';
import { GameStatusType } from '../hooks/useGameStatus';

const Timer = (gameStatus: GameStatusType): number[] => {
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (gameStatus === GameStatusType.PLAYING) {
      interval = global.setInterval(() => {
        setElapsedTime((prev) => {
          const total = prev + 1;
          return total;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [elapsedTime, gameStatus]);

  useEffect(() => {
    if (gameStatus === GameStatusType.NOT_STARTED) {
      setElapsedTime(0);
    }
  }, [gameStatus]);

  return [elapsedTime];
};

export default Timer;
