/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components';

type GridContainer = {
  ref: any;
};

type Grid = {
  height: number;
  width: number;
};

export const StyledGrid = styled.div<Grid>`
  display: grid;
  padding: 10px 15px 30px 15px;
  background-color: none;
  touch-action: manipulation;
  grid-template-rows: repeat(
    ${(props) => props.height},
    calc((35px * ${(props) => props.width}) / ${(props) => props.width})
  );
  grid-template-columns: repeat(${(props) => props.width}, 1fr);
  width: 100%;
  height: 100%;
  max-width: calc(35px * ${(props) => props.width});
  margin-top: 10px;
  @media (max-width: 800px) {
    grid-template-rows: repeat(
      ${(props) => props.height},
      calc((35px * ${(props) => props.width}) / ${(props) => props.width})
    );
    max-width: calc(35px * ${(props) => props.width});
    min-width: calc(35px * ${(props) => props.width});
  }
  margin: 0 auto;
`;

export const StyledGridContainer = styled.div<GridContainer>`
  width: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  @media (max-width: 800px) {
    justify-content: flex-start;
  }
  margin: 20px;
`;
