import React, { useEffect } from 'react';
import Footer from './components/Footer';
import BugSweeper from './components/BugSweeper';
import './App.css';

import virus from './components/icons/coronavirus.svg';
import mask from './components/icons/medical-mask.svg';
import home from './components/icons/home.svg';
import close from './components/icons/close.svg';
import vaccine from './components/icons/vaccine.svg';

function App(): JSX.Element {
  useEffect(() => {
    window.onunload = () => {
      window.scrollTo(0, 0);
    };
  }, []);

  return (
    <div className="App">
      {/* preloading images  */}
      <img
        src={virus}
        alt="coronavirus"
        className="bug"
        style={{ display: 'none' }}
      />
      <img
        src={home}
        alt="house"
        className="flagged"
        style={{ display: 'none' }}
      />
      <img
        src={close}
        alt="wrong bug"
        className="wrong"
        style={{ display: 'none' }}
      />
      <img
        src={mask}
        className="unsure"
        alt="mask"
        style={{ display: 'none' }}
      />
      <img
        src={vaccine}
        className="vaccine"
        alt="vaccine"
        style={{ display: 'none' }}
      />
      <BugSweeper />
      <Footer />
    </div>
  );
}

export default App;
