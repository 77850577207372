import React, { useCallback, useState, useEffect } from 'react';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { levels } from '../levels';
import { createGrid } from '../gameHelpers';

// Components
import GameBoard from './GameBoard';
import GameDisplay from './GameDisplay';
import Settings from './Settings';
import HowTo from './HowTo';
import YouWin from './YouWin';

// Styled Components
import { StyledGameWrapper, StyledGame } from './styles/StyledGame';
import { StyledModalButton } from './styles/StyledButtons';
import StyledGameOptions from './styles/StyledGameOptions';
import StyledNewGame from './styles/StyledNewGame';

// Custom Hooks
import useGrid from '../hooks/useGrid';
import useGameStatus from '../hooks/useGameStatus';
import useLocalStorage from '../hooks/useLocalStorage';
import { Cell } from '../hooks/useGrid';
import { GameStatusType } from '../hooks/useGameStatus';

const BugSweeper = () => {
  const [level, setLevel] = useLocalStorage('level', 'easy');
  const [game, setGame] = useLocalStorage('game', levels.easy);
  const [grid, setGrid, clearGrid] = useGrid(game);
  const [startGame, resetGame, gameStatus] = useGameStatus(game, grid);
  const [flags, setFlags] = useState(0);
  const [isShowingSettings, setIsShowingSettings] = useLocalStorage(
    'isShowingSettings',
    window.innerWidth > 800,
  );
  const [isShowingHowTo, setIsShowingHowTo] = useLocalStorage(
    'isShowingHowTo',
    window.innerWidth > 800,
  );

  useEffect(() => {
    const item = localStorage.getItem('game');
    const storedGame = item && JSON.parse(item);
    if (storedGame && storedGame.bugs) {
      storedGame.outbreaks = storedGame.bugs;
      delete storedGame['bugs'];
      setGame(storedGame);
    }
  }, [setGame]);

  useEffect(() => {
    if (gameStatus === GameStatusType.WON) {
      setGrid((prev: Cell[][]) => {
        const updatedGrid = prev.map((row) =>
          row.map((cell) => {
            if (cell.bug === true && cell.flagged === false) {
              return { ...cell, flagged: true, unsure: false };
            }
            return cell;
          }),
        );
        return updatedGrid;
      });
    }
  }, [gameStatus, setGrid]);

  const newGame = () => {
    setGrid(createGrid(game.height, game.width));
    setFlags(0);
    resetGame();
  };

  const changeGame = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.type === 'change') {
      setGame({ ...game, [e.target.name]: Number(e.target.value) });
    } else {
      setGame(levels[e.target.id]);
    }
  };

  const changeLevel = (e: React.ChangeEvent<HTMLInputElement>) => {
    newGame();
    if (
      e.target.id === 'easy' ||
      e.target.id === 'normal' ||
      e.target.id === 'hard'
    ) {
      changeGame(e);
      setLevel(e.target.id);
    } else {
      setLevel('custom');
    }
  };

  const clearTiles = useCallback(
    (x, y) => {
      clearGrid(x, y);
    },
    [clearGrid],
  );

  const flagTile = useCallback(
    (x, y) => {
      setGrid((prev: Cell[][]) => {
        const updatedGrid = [...prev];
        if (prev[y][x].flagged === false && prev[y][x].unsure === false) {
          updatedGrid[y][x] = { ...updatedGrid[y][x], flagged: true };
        } else if (prev[y][x].unsure === true) {
          updatedGrid[y][x] = { ...updatedGrid[y][x], unsure: false };
        } else {
          updatedGrid[y][x] = {
            ...updatedGrid[y][x],
            flagged: false,
            unsure: true,
          };
        }
        return updatedGrid;
      });
    },
    [setGrid],
  );

  useEffect(() => {
    setFlags(
      grid.reduce((n, row) => {
        row.forEach((cell) => {
          if (cell.flagged) {
            n += 1;
          }
        });
        return n;
      }, 0),
    );
  }, [grid]);

  return (
    <StyledGameWrapper>
      <StyledGame>
        <YouWin gameStatus={gameStatus} />
        <GameDisplay
          flags={flags}
          outbreaks={game.outbreaks}
          gameStatus={gameStatus}
        />
        <StyledGameOptions>
          <StyledModalButton
            role="button"
            show={isShowingHowTo}
            aria-label={isShowingHowTo ? 'Hide how-to' : 'Show how-to'}
            onClick={() => setIsShowingHowTo(!isShowingHowTo)}>
            <FontAwesomeIcon icon={faQuestionCircle} />
          </StyledModalButton>
          <StyledNewGame type="button" onClick={newGame}>
            New Game
          </StyledNewGame>
          <StyledModalButton
            role="button"
            show={isShowingSettings}
            aria-label={isShowingSettings ? 'Hide settings' : 'Show settings'}
            onClick={() => setIsShowingSettings(!isShowingSettings)}>
            <FontAwesomeIcon icon={faSlidersH} />
          </StyledModalButton>
        </StyledGameOptions>
        <GameBoard
          startGame={startGame}
          game={game}
          grid={grid}
          clearTiles={clearTiles}
          gameStatus={gameStatus}
          flagTile={flagTile}
        />
        {isShowingHowTo && (
          <HowTo toggleHowTo={() => setIsShowingHowTo(!isShowingHowTo)} />
        )}
        {isShowingSettings && (
          <Settings
            level={level}
            game={game}
            toggleSettings={() => setIsShowingSettings(!isShowingSettings)}
            changeLevel={changeLevel}
            changeGame={changeGame}
          />
        )}
      </StyledGame>
    </StyledGameWrapper>
  );
};

export default React.memo(BugSweeper);
