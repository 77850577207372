import React from 'react';
import StyledFooter from './styles/StyledFooter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGithub,
  faLinkedin,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

const Footer = (): JSX.Element => {
  return (
    <StyledFooter>
      <a href="https://sofiamade.it">Sofia Made It</a>
      <div>
        <a href="https://github.com/sofialevin" aria-label="Github">
          <FontAwesomeIcon icon={faGithub} />
        </a>
        <a
          href="https://www.linkedin.com/in/sofiaflevin/"
          aria-label="LinkedIn">
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
        <a href="https://twitter.com/sofiaflevin" aria-label="Twitter">
          <FontAwesomeIcon icon={faTwitter} />
        </a>
      </div>
    </StyledFooter>
  );
};

export default Footer;
