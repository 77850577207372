import { useEffect, useState } from 'react';
import StyledDisplay from './styles/StyledDisplay';
import { pad } from '../gameHelpers';
import useTimer from '../hooks/useTimer';
import { GameStatusType } from './../hooks/useGameStatus';

const GameDisplay = ({
  flags,
  outbreaks,
  gameStatus,
}: {
  flags: number;
  outbreaks: number;
  gameStatus: GameStatusType;
}): JSX.Element => {
  const [emoji, setEmoji] = useState('😀');
  const [elapsedTime] = useTimer(gameStatus);

  useEffect(() => {
    switch (gameStatus) {
      case GameStatusType.LOST:
        setEmoji('😵');
        break;
      case GameStatusType.WON:
        setEmoji('😎');
        break;
      default:
        setEmoji('😀');
    }
  }, [gameStatus]);

  return (
    <StyledDisplay>
      <div className="outbreaks">
        {outbreaks - flags < 0 ? '000' : pad(outbreaks - flags, 3)}
      </div>
      <div className="emoji">{emoji}</div>
      <div className="time">{pad(elapsedTime, 3)}</div>
    </StyledDisplay>
  );
};

export default GameDisplay;
