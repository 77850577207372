import React, { useRef, useCallback } from 'react';
import { StyledGrid, StyledGridContainer } from './styles/StyledGrid';
import GameCell from './GameCell';
import { Level } from '../levels';
import { Cell } from '../hooks/useGrid';
import { GameStatusType } from '../hooks/useGameStatus';

const GameBoard = ({
  game,
  grid,
  clearTiles,
  gameStatus,
  flagTile,
  startGame,
}: {
  game: Level;
  grid: Cell[][];
  clearTiles: (x: number, y: number) => void;
  gameStatus: GameStatusType;
  flagTile: (x: number, y: number) => void;
  startGame: (x: number, y: number) => void;
}): JSX.Element => {
  const scrollElement = useRef<HTMLElement>(null);

  const getScrollLeft = useCallback(() => {
    if (null !== scrollElement) {
      return scrollElement.current?.scrollLeft || 0;
    }
    return 0;
  }, []);

  return (
    <StyledGridContainer ref={scrollElement}>
      <StyledGrid width={game.width} height={game.height} id="grid">
        {grid.map((row, y) =>
          row.map((cell, x) => (
            <GameCell
              index={[y, x]}
              getScrollLeft={getScrollLeft}
              clearTiles={() => clearTiles(x, y)}
              // eslint-disable-next-line react/no-array-index-key
              key={x}
              cell={cell}
              gameStatus={gameStatus}
              flagTile={() => flagTile(x, y)}
              startGame={() => startGame(x, y)}
            />
          )),
        )}
      </StyledGrid>
    </StyledGridContainer>
  );
};

export default GameBoard;
