import styled from 'styled-components';

type ModalButton = {
  show: boolean;
};

type CloseButton = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  panel: any;
};

export const StyledModalButton = styled.button<ModalButton>`
  border: none;
  font-size: 30px;
  color: ${(props) => (props.show === true ? '#3b6978' : '#858586')};
  cursor: pointer;
  background-color: transparent;
  &:hover {
    color: ${(props) => (props.show === true ? '#325965' : '#6a6a6b')};
  }
`;

export const StyledCloseButton = styled.button<CloseButton>`
  border: none;
  color: #393e46;
  padding: 0;
  font-size: 20px;
  background: none;
  cursor: pointer;
  display: flex;
  align-self: ${(props) =>
    props.panel === 'settings' ? 'flex-end' : 'flex-start'};
  cursor: pointer;
  @media (max-width: 800px) {
    font-size: 25px;
    align-self: flex-end;
  }
`;
