import StyledYouWin from './styles/StyledYouWin';
import { GameStatusType } from '../hooks/useGameStatus';

const YouWin = ({
  gameStatus,
}: {
  gameStatus: GameStatusType;
}): JSX.Element => (
  <StyledYouWin gameStatus={gameStatus}>
    <span role="img" aria-label="celebration emoji">
      🎉
    </span>
    <p> You Win! </p>
    <span role="img" aria-label="celebration emoji">
      🎉
    </span>
    <p>(but keep wearing your mask!)</p>
  </StyledYouWin>
);

export default YouWin;
