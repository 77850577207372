import styled from 'styled-components';

export const StyledGameWrapper = styled.div`
  width: 100vw;
  position: relative;
  flex: 1;
`;

export const StyledGame = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0 40px;
  @media (max-width: 800px) {
    padding: 0 20px;
  }
  max-width: 1680px;
  margin: 0 auto;
`;
