import React from 'react';
import StyledCell from './styles/StyledCell';
import virus from './icons/coronavirus.svg';
import mask from './icons/medical-mask.svg';
// import home from './icons/home.svg';
import vaccine from './icons/vaccine.svg';
import close from './icons/close.svg';
import { Cell } from '../hooks/useGrid';
import { GameStatusType } from '../hooks/useGameStatus';

const GameCell = ({
  cell,
  clearTiles,
  gameStatus,
  flagTile,
  startGame,
  getScrollLeft,
  index,
}: {
  cell: Cell;
  clearTiles: () => void;
  gameStatus: GameStatusType;
  flagTile: () => void;
  startGame: () => void;
  getScrollLeft: () => number;
  index: [number, number];
}) => {
  let clickHoldTimer: NodeJS.Timeout;

  const handleMouseDown = () => {
    const currentScrollY = window.pageYOffset;
    const currentScrollX = getScrollLeft();
    clickHoldTimer = setTimeout(() => {
      // Action to be performed after holding down mouse
      if (
        cell.cleared === false &&
        window.pageYOffset === currentScrollY &&
        gameStatus === GameStatusType.PLAYING &&
        currentScrollX === getScrollLeft()
      ) {
        flagTile();
      }
    }, 650); // Change 1000 to number of milliseconds required for mouse hold
  };

  const handleMouseUp = () => {
    clearTimeout(clickHoldTimer);
  };

  const cellFill = () => {
    if (cell.cleared) {
      if (!cell.bug && !cell.flagged) {
        return <p>{cell.adjacent}</p>;
      } else if (cell.bug) {
        return <img src={virus} alt="coronavirus" />;
      }
    }
    if (cell.unsure === true) {
      return <img src={mask} alt="mask" />;
    }

    if (
      cell.flagged &&
      cell.bug === false &&
      gameStatus === GameStatusType.LOST
    ) {
      return <img src={close} alt="crossed out coronavirus" />;
    }
    if (
      (cell.flagged && gameStatus !== GameStatusType.LOST) ||
      (cell.bug && gameStatus === GameStatusType.WON)
    ) {
      return <img src={vaccine} alt="vaccine" />;
    }
    return;
  };

  const handleClick = (e: { altKey: boolean }) => {
    if (e.altKey === true && gameStatus === GameStatusType.PLAYING) {
      flagTile();
    } else {
      if (gameStatus === GameStatusType.NOT_STARTED) {
        startGame();
        clearTiles();
      }
      if (
        cell.flagged === false &&
        cell.cleared === false &&
        gameStatus === GameStatusType.PLAYING
      ) {
        clearTiles();
      }
    }
  };

  const handleContext = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (cell.cleared === false && gameStatus === GameStatusType.PLAYING) {
      flagTile();
    }
  };

  return (
    <StyledCell
      role="button"
      aria-label={`row ${index[0] + 1}, cell ${index[1] + 1}`}
      onContextMenu={handleContext}
      onClick={handleClick}
      onTouchStart={handleMouseDown}
      onTouchEnd={handleMouseUp}
      cell={cell}
      gameStatus={gameStatus}>
      {cellFill()}
    </StyledCell>
  );
};

export default React.memo(GameCell);
