import styled from 'styled-components';

const StyledDisplay = styled.div`
  margin: 2%;
  font-size: 50px;
  width: 350px;
  height: 75px;
  border-radius: 20px;
  color: #00adb5;
  background: linear-gradient(-60deg, #46404f 53%, #4b4452 53%);
  box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Alarm Clock';
  .outbreaks,
  .time {
    display: flex;
    width: 110px;
    justify-content: center;
  }
  .emoji {
    margin: 0 20px;
  }
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none;
  @media (max-width: 800px) {
    margin: 20px;
  }
`;

export default StyledDisplay;
