import styled from 'styled-components';

const StyledNewGame = styled.button`
    color: #fff;
    background: #3b6978;
    text-shadow: 0 -1px 0 rgba(0,0,0,.12);
    box-shadow: 0 2px 0 rgba(0,0,0,.045);
    height: 40px;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    margin: 0 20px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10+ and Edge */
    user-select: none;
    &:hover {
        background-color: #325965;
    }
`;

export default StyledNewGame;
