import { useState, useEffect } from 'react';
import { createGrid } from '../gameHelpers';

export interface Cell {
  adjacent: number;
  bug: boolean;
  cleared: boolean;
  flagged: boolean;
  unsure: boolean;
}

export interface GameType {
  height: number;
  width: number;
  outbreaks: number;
}

const useGrid = (
  game: GameType,
): [
  Cell[][],
  React.Dispatch<React.SetStateAction<null | Cell[][]>>,
  (xAxis: number, yAxis: number) => void,
] => {
  const [grid, setGrid] = useState<Cell[][]>(
    createGrid(game.height, game.width),
  );

  useEffect(() => {
    setGrid(createGrid(game.height, game.width));
  }, [game.height, game.width]);

  const clearGrid = (xAxis: number, yAxis: number) => {
    const floodFill = (x: number, y: number, prevGrid: Cell[][]) => {
      let updatedGrid = [...prevGrid];

      if (
        updatedGrid[y] &&
        updatedGrid[y][x] &&
        updatedGrid[y][x].cleared === false
      ) {
        if (updatedGrid[y][x].bug === true) {
          updatedGrid = prevGrid.map((row) =>
            row.map((cell) => {
              if (
                cell.bug === true ||
                (cell.flagged === true && cell.bug === false)
              ) {
                return { ...cell, cleared: true, unsure: false };
              }
              return cell;
            }),
          );
          return updatedGrid;
        }
        if (
          updatedGrid[y][x].adjacent > 0 &&
          updatedGrid[y][x].flagged === false
        ) {
          updatedGrid[y][x] = {
            ...updatedGrid[y][x],
            cleared: true,
            unsure: false,
          };
          return updatedGrid;
        }
        if (updatedGrid[y][x].flagged === false) {
          updatedGrid[y][x] = {
            ...updatedGrid[y][x],
            cleared: true,
            unsure: false,
          };
        }
        floodFill(x - 1, y - 1, updatedGrid);
        floodFill(x - 1, y, updatedGrid);
        floodFill(x - 1, y + 1, updatedGrid);
        floodFill(x, y - 1, updatedGrid);
        floodFill(x, y + 1, updatedGrid);
        floodFill(x + 1, y - 1, updatedGrid);
        floodFill(x + 1, y, updatedGrid);
        floodFill(x + 1, y + 1, updatedGrid);
      }
      return updatedGrid;
    };
    setGrid((prev) => floodFill(xAxis, yAxis, prev));
  };
  return [grid, setGrid, clearGrid];
};

export default useGrid;
