/* eslint-disable max-len */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import StyledPanel from './styles/StyledPanel';
import { StyledCloseButton } from './styles/StyledButtons';

const HowToPlay = ({
  toggleHowTo,
}: {
  toggleHowTo: () => void;
}): JSX.Element => (
  <StyledPanel panel="how-to">
    <StyledCloseButton
      onClick={toggleHowTo}
      type="button"
      panel="how-to"
      aria-label="Close how-to"
      role="button">
      <FontAwesomeIcon icon={faTimes} />
    </StyledCloseButton>
    <h2>How to Play</h2>
    <ol style={{ textAlign: 'left', paddingLeft: '30px' }}>
      <li style={{ marginBottom: '20px' }}>
        Click on the game tiles to test for coronavirus. The tiles will either
        be clear or they&apos;ll indicate how many nearby outbreaks there are.
      </li>
      <li style={{ marginBottom: '20px' }}>
        Use those numbers to narrow down on the outbreaks. When you find an
        outbreak, right-click, option/alt-click or tap and hold on mobile to set
        a vaccination center to help stop the spread.
      </li>
      <li style={{ marginBottom: '20px' }}>
        When a tile already has a vaccination center you can right-click it
        again to switch it to a mask mandate. This is useful when you
        haven&lsquo;t been able to narrow down on the location of the outbreak
        yet and to correctly distribute vaccine resources.
      </li>
      <li style={{ marginBottom: '20px' }}>
        Clear all the tiles to win the game!
      </li>
    </ol>
  </StyledPanel>
);

export default HowToPlay;
