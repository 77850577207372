/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components';
import testIcon from '../icons/swab.png';
import { GameStatusType } from '../../hooks/useGameStatus';

const colors = {
  1: '#0057A3',
  2: '#16641F',
  3: '#B30900',
  4: '#970BAD',
  5: '#85144b',
  6: '#196161',
  7: '#111111',
  8: '#595959',
};

type CellProps = {
  cell: any;
  gameStatus: any;
};

const StyledCell = styled.button<CellProps>`
  border: none;
  background: ${({ cell }) => (cell.cleared ? 'white' : '#737275')};
  box-shadow: 0 10px 24px 0px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  margin: 0.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  ${({ cell }) =>
    cell.adjacent > 0 && cell.cleared && `color: ${colors[cell.adjacent]}`};
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  img {
    max-width: 70%;
  }
  ${({ gameStatus, cell }) =>
    (gameStatus === GameStatusType.PLAYING ||
      gameStatus === GameStatusType.NOT_STARTED) &&
    cell.cleared === false &&
    `cursor: url(${testIcon}), default`};
  & > p {
    visibility: ${({ cell }) =>
      cell.adjacent === 0 && !cell.bug && !cell.flagged && 'hidden'};
  }
  padding: 0;
  height: 34px;
  width: 34px;
`;

export default StyledCell;
