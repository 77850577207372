import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { StyledForm, StyledLevel } from './styles/StyledSettings';
import StyledPanel from './styles/StyledPanel';
import { StyledCloseButton } from './styles/StyledButtons';
import { Level } from '../levels';

interface SettingsProps {
  changeGame: (e: React.ChangeEvent<HTMLInputElement>) => void;
  game: Level;
  toggleSettings: () => void;
  changeLevel: (e: React.ChangeEvent<HTMLInputElement>) => void;
  level: string;
}

const Settings = ({
  changeGame,
  game,
  toggleSettings,
  changeLevel,
  level,
}: SettingsProps): JSX.Element => (
  <StyledPanel panel="settings">
    <StyledCloseButton
      onClick={toggleSettings}
      panel="settings"
      aria-label="Close game settings"
      role="button">
      <FontAwesomeIcon icon={faTimes} />
    </StyledCloseButton>
    <h2>Game Settings</h2>
    <StyledLevel
      id="easy"
      role="button"
      onClick={changeLevel}
      tabIndex={0}
      level={level}>
      Easy - 9x9 / 10 Outbreaks
    </StyledLevel>
    <StyledLevel
      id="normal"
      role="button"
      onClick={changeLevel}
      tabIndex={0}
      level={level}>
      Normal - 16x16 / 40 Outbreaks
    </StyledLevel>
    <StyledLevel
      id="hard"
      role="button"
      onClick={changeLevel}
      tabIndex={0}
      level={level}>
      Hard - 16x30 / 99 Outbreaks
    </StyledLevel>
    <StyledLevel level={level} id="custom" onMouseDown={changeLevel}>
      <StyledForm>
        {`Custom - ${game.width}x${game.height} / ${game.outbreaks} Outbreaks`}
        <label htmlFor="width">
          Width:
          <input
            type="range"
            name="width"
            id="width"
            onChange={changeGame}
            value={game.width}
            min="9"
            max="50"
            aria-valuemin={9}
            aria-valuemax={50}
            aria-valuenow={game.width}
          />
        </label>
        <label htmlFor="height">
          Height:
          <input
            type="range"
            name="height"
            id="height"
            onChange={changeGame}
            value={game.height}
            min="9"
            max="50"
            aria-valuemin={9}
            aria-valuemax={50}
            aria-valuenow={game.height}
          />
        </label>
        <label htmlFor="outbreaks">
          Outbreaks:
          <input
            type="range"
            name="outbreaks"
            id="outbreaks"
            min="1"
            max={
              game.height * game.width - 30 < 200
                ? game.height * game.width - 30
                : 200
            }
            onChange={changeGame}
            value={game.outbreaks}
            aria-valuemin={1}
            aria-valuemax={
              game.height * game.width - 30 < 200
                ? game.height * game.width - 30
                : 200
            }
            aria-valuenow={game.outbreaks}
          />
        </label>
      </StyledForm>
    </StyledLevel>
  </StyledPanel>
);

export default Settings;
