import styled from 'styled-components';

const StyledFooter = styled.div`
  margin: 40px 0 20px;
  @media (max-width: 800px) {
    margin: 20px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    text-decoration: none;
    color: #3b6978;
    font-size: 18px;
    margin: 5px;
    &:hover {
      color: #325965;
  }
  }
  div {
    display: flex;
    a {
      margin: 10px;
      font-size: 25px;
    }
  }
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none;
`;

export default StyledFooter;
