import styled from 'styled-components';
import { GameStatusType } from '../../hooks/useGameStatus';

type YouWin = {
  gameStatus: GameStatusType;
};

const StyledYouWin = styled.div<YouWin>`
  display: ${(props) =>
    props.gameStatus === GameStatusType.WON ? 'flex' : 'none'};
  flex-wrap: wrap;
  position: absolute;
  justify-content: center;
  top: 40%;
  left: 50%;
  width: 300px;
  margin-left: -150px;
  align-items: center;
  font-size: 35px;
  background: #e4e4e4f2;
  border: 1px solid #dee0e0;
  padding: 10px 20px;
  border-radius: 5px;
  p {
    margin: 15px;
  }
  p:not(:first-of-type) {
    font-size: 18px;
    margin: 15px 0;
  }
  box-sizing: border-box;
  color: #393e46;
  box-shadow: 10px 10px 15px 0px rgba(0, 0, 0, 0.25);
`;

export default StyledYouWin;
